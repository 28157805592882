import * as React from "react";
import Layout from "../components/Layout";
import * as Gatsby from "gatsby";
import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Progress,
  CircularProgress,
  CircularProgressLabel,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Center,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";

function CallToActionWithAnnotation() {
  const [progressVal, setProgressVal] = React.useState(0);
  const [progressTodayVal, setProgressTodayVal] = React.useState(0);
  const [countdown, setCountdown] = React.useState(0);
  const [circleColor, setCircleColor] = React.useState("green.400");
  const timeStart = new Date(2023, 0, 20); // 1 月 20 日
  const timeEnd = new Date(2023, 3, 30); // 4 月底
  const timeCntdownEnd = new Date(2023, 2, 1); // 3 月初
  // useEffect()是通用的副效应钩子。找不到对应的钩子时，就可以用它。
  // 纯函数内部只有通过间接的手段（即通过其他函数调用），才能包含副效应。
  // React 的函数组件只应该做一件事情：返回组件的 HTML 代码，而没有其他的功能。
  React.useEffect(() => {
    // 副作用就是做纯函数不做的事，纯函数从输入到输出就完事了
    const intervalTimer = setInterval(() => {
      let timeNow = new Date();
      let timeTodayStart = new Date(
        timeNow.getFullYear(),
        timeNow.getMonth(),
        timeNow.getDate() // bug: 注意 getDay 是 day of week
      );
      let progressValNew = (timeNow - timeStart) / (timeEnd - timeStart);
      let progressTodayValNew = (timeNow - timeTodayStart) / 24 / 3600 / 1000;
      let countdownDays = (timeCntdownEnd - timeNow) / 24 / 3600 / 1000;
      progressValNew = progressValNew < 100 ? progressValNew * 100 : 100;
      progressTodayValNew = 100 - progressTodayValNew * 100;
      countdownDays = countdownDays > 0 ? countdownDays : 0;
      if (progressTodayValNew < 30 && progressTodayValNew > 20) {
        setCircleColor("yellow.400");
      } else if (progressTodayValNew <= 20) {
        setCircleColor("red.400");
      } else {
        setCircleColor("green.400");
      }
      setProgressVal(progressValNew);
      setProgressTodayVal(progressTodayValNew);
      setCountdown(countdownDays);
      // console.log(progressValNew);
      // console.log(progressTodayValNew);
    }, 1000);
    // 必须清除副作用，否则每次加载每次都新增了定时器
    return () => {
      clearInterval(intervalTimer);
    };
  }, []);
  return (
    <>
      <Container maxW={"3xl"} mb={-12}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Helmet title={`Yuhan.Tech`} defer={false} />

          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
            mb={-8}
          >
            <Text as={"span"} color={"green.400"}>
              争分夺秒 谨防沉迷
            </Text>
            <br />
          </Heading>
          <Center mt={-12}>
            <Box mr={4} border="1px" p={2} borderColor="gray.400">
              <Stat>
                <StatLabel>距下一个里程碑</StatLabel>
                <StatNumber>{countdown.toFixed(0)}</StatNumber>
                <StatHelpText>三月一日</StatHelpText>
              </Stat>
            </Box>
            <Box border="1px" p={2} borderColor="gray.400">
              <Stat>
                <StatLabel>今日电量</StatLabel>
                <CircularProgress
                  value={progressTodayVal}
                  capIsRound
                  color={circleColor}
                  size="65px"
                >
                  <CircularProgressLabel>
                    {progressTodayVal.toFixed(2)}
                  </CircularProgressLabel>
                </CircularProgress>
              </Stat>
            </Box>
          </Center>
          <Text color={"gray.500"}>
            {progressVal.toFixed(2)}% of the 100-day sprint for graduation
            project has been passed.
          </Text>
          <Progress
            colorScheme="green"
            size="lg"
            value={progressVal}
            isAnimated
            hasStripe
          />
        </Stack>
      </Container>
    </>
  );
}

const IndexPage = () => {
  return (
    <Layout>
      <CallToActionWithAnnotation />
    </Layout>
  );
};

export default IndexPage;
